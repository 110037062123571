import React from "react";
import SEO from "components/seo";
import useHerosTypesData from "shared/Hooks/PageHeaders/useHerosTypesData";
import { useContactPersonData } from "shared/Hooks/ContactPerson";
import { ContactPerson } from "sections/Homepage";
import { CenteredTextBox } from "components/TextBoxes";
import { BigHero } from "components/BigHero";
import { REALISATIONS } from "shared/Types/heros";
import { CasesList } from "sections/CasesNew/";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Realizacje = () => {
  const data = useHerosTypesData(REALISATIONS);
  const cases = useCaseData();
  const cases_array = Object.values(cases);

  return (
    <>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <CenteredTextBox titleTag="h2" title="Kompleksowe rozwiązania">
        Szerokie portfolio realizacji to nasza najlepsza wizytówka. Zobacz, w
        jakich obszarach działamy i zainspiruj się. Razem możemy stworzyć coś
        wielkiego!
      </CenteredTextBox>
      <div id="lista">
        <CasesList id="list" data={cases_array} />
      </div>
      {/*<ContactPerson personData={useContactPersonData().jakub_furman} />*/}
    </>
  );
};

export default Realizacje;

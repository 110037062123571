import React from "react";

import {
  Container,
  Item,
  ImageBox,
  Background,
  TextBox,
  Title,
  Text,
  Detail,
} from "./styles";

export const CasesList = ({ data }) => {
  return (
    <Container>
      {data.map((item, i) => (
        <Item key={i} to={`/realizacje/${item.slug}`}>
          <ImageBox color={item.color}>
            {item.list.background && (
              <Background>{item.list.background}</Background>
            )}
            <Detail>{item.list.detail}</Detail>
          </ImageBox>
          <TextBox>
            <Title>{item.title}</Title>
            <Text>{item.description || item.hero.title}</Text>
          </TextBox>
        </Item>
      ))}
    </Container>
  );
};

import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";
import { Link } from "gatsby";

export const Container = styled(SectionWrapper)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 64px;
`;

export const ImageBox = styled.div`
  background-color: ${({ color }) => (color ? color : "red")};
  position: relative;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
`;

export const Detail = styled.div`
  z-index: 2;
  transition: 0.2s transform ease;
  transform: scale(1.01);

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: 24px 0 12px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 12px 0;
  }
`;

export const Title = styled.div`
  font-size: 2.2rem;
  font-weight: 800;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 1.8rem;
  }
`;

export const Text = styled.div`
  font-size: 1.4rem;
  margin-top: 6px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 1rem;
    margin-top: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 1.2rem;
    margin-top: 6px;
  }
`;

export const Item = styled(Link)`
  width: calc(50% - 24px);
  margin-bottom: 48px;
  &:not(:nth-child(2n)) {
    margin-right: 48px;
  }
  &:hover {
    ${Detail} {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: calc(50% - 18px);
    margin-bottom: 36px;
    &:not(:nth-child(2n)) {
      margin-right: 36px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: calc(50% - 12px);
    margin-bottom: 24px;
    &:not(:nth-child(2n)) {
      margin-right: 24px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    width: 100%;
    margin-right: 0 !important;
  }
`;
